<template>
  <div  style="margin-left:20px;">
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <el-form-item :label="$t('System.SecurityConfigPage.PasswordComplexity')" required>
        <el-input v-model="form.Complexpass" size="small" style="width: 250px" disabled :placeholder="$t('System.SecurityConfigPage.PasswordComplexityHolder')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.minPasswordLength')" required>
        <el-input v-model.trim="form.passwordlength" size="small" disabled placeholder="8" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.DefaultPassword')" prop="defaultPass">
        <el-input v-model="form.defaultPass" size="small" placeholder="" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.NumberIncorrectPasswords')" prop="errorNumber">
        <el-input v-model.trim="form.errorNumber" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" :placeholder="$t('System.SecurityConfigPage.inputNumber')" style="width: 250px"></el-input><span style="margin-left:10px;">{{ $t("System.SecurityConfigPage.order") }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.UserLockTime')" prop="lockTime">
        <el-input v-model.trim="form.lockTime" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" :placeholder="$t('System.SecurityConfigPage.UserLockPlaceholder')" style="width: 250px"></el-input><span style="margin-left:10px;">{{ $t("System.SecurityConfigPage.minute") }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.ValidityPeriod')" prop="serverTimeout">
        <el-input v-model.trim="form.serverTimeout" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" :placeholder="$t('System.SecurityConfigPage.inputNumber')" style="width: 250px"></el-input><span style="margin-left:10px;">{{ $t("System.SecurityConfigPage.minute") }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.ClientValidityPeriod')" prop="clientTimeout">
        <el-input v-model.trim="form.clientTimeout" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" :placeholder="$t('System.SecurityConfigPage.inputNumber')" style="width: 250px"></el-input><span style="margin-left:10px;">{{ $t("System.SecurityConfigPage.hour") }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.UserBoundDevice')" prop="devMaxCounts">
        <el-input v-model.trim="form.devMaxCounts" onkeyup="value=value.replace(/[^\d]/g,'')" size="small" :placeholder="$t('System.SecurityConfigPage.inputNumber')" style="width: 250px"></el-input><span style="margin-left:10px;">{{ $t("System.SecurityConfigPage.tower") }}</span>
      </el-form-item>
      <!-- //仅允许预录入设备 0禁止 1是 -->
      <el-form-item :label="$t('System.SecurityConfigPage.PreInputDeviceLogin')">
        <el-radio-group v-model="form.forbidAutoRegister" class="adio">
          <el-radio v-model="form.forbidAutoRegister" label="1">{{ $t("System.SecurityConfigPage.correct") }}</el-radio>
          <el-radio v-model="form.forbidAutoRegister" label="0">{{ $t("System.SecurityConfigPage.deny") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.SwitchUsers')">
        <el-radio-group v-model="form.allowChangeUser" class="adio">
          <el-radio v-model="form.allowChangeUser" label="yes">{{ $t("System.SecurityConfigPage.correct") }}</el-radio>
          <el-radio v-model="form.allowChangeUser" label="no">{{ $t("System.SecurityConfigPage.deny") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.ProhibitRiskIPLogin')">
        <el-radio-group v-model="form.banDangerIp" class="adio">
          <el-radio v-model="form.banDangerIp" label="1">{{ $t("System.SecurityConfigPage.correct") }}</el-radio>
          <el-radio v-model="form.banDangerIp" label="0">{{ $t("System.SecurityConfigPage.deny") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="">
        <!-- <el-button type="primary" @click="securityadd" size="small">保存</el-button> -->
        <el-button style="width:80px;" type="primary" @click="submitForm('form')" size="small">{{ $t("public.Save") }}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },
  data() {
    let validdefaultPass = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
      if (!reg.test(value)) {
        callback(new Error(this.$t('System.SecurityConfigPage.defaultPassReg')))
      } else {
        callback()
      }
    }
    return {
      visibaelFlag: false, //弹框
      titleInfo: '',
      form: {
        Complexpass: '',
        passwordlength: '',
        defaultPass: '', //默认密码validdefaultPass    ---p@ssw0rd
        errorNumber: '', //错误密码次数
        lockTime: '1', // 用户锁定时间：
        serverTimeout: '', //平台登录状态有效时期
        clientTimeout: '', //客户端登录状态有效期
        devMaxCounts: '', //单用户绑定设备数
        forbidAutoRegister:'0',//仅允许预录入设备登录0-否 1是
        allowChangeUser: 'no', //允许客户端切换用户
        banDangerIp:'1',//增加“风险IP禁止登录”配置入参
      },
      rules: {
        // 密码复杂度
        Complexpass: [{ required: false, message: ' ' }],
        // 密码最小长度
        passwordlength: [{ required: false, message: ' ' }],
        //默认密码   /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,30}/;
        defaultPass: [
          { required: true, message: this.$t('System.SecurityConfigPage.defaultPassVoid'), trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: this.$t('System.SecurityConfigPage.defaultPassReg'),
            trigger: 'blur'
          },
          { validator: validdefaultPass, trigger: 'blur' }
        ],
        // 错误密码次数
        errorNumber: [
          { required: true, message: this.$t('System.SecurityConfigPage.errorNumberVoid'), trigger: 'blur' }
        ],
        // 用户锁定时间
        lockTime: [
          { required: true, message: this.$t('System.SecurityConfigPage.lockTimeVoid'), trigger: 'blur' }
        ],
        //  平台登录状态有效期
        serverTimeout: [
          {
            required: true,
            message: this.$t('System.SecurityConfigPage.serverTimeoutVoid'),
            trigger: 'blur'
          }
        ],
        //  客户端登录状态有效期
        clientTimeout: [
          {
            required: true,
            message: this.$t('System.SecurityConfigPage.clientTimeoutVoid'),
            trigger: 'blur'
          }
        ],
        //单用户绑定设备数
        devMaxCounts: [
          {
            required: true,
            message: this.$t('System.SecurityConfigPage.devMaxCountsVoid'),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  mounted() {
    this.SMSlist()
  },

  methods: {
    // 进入页面接口请求
    async SMSlist() {
      var param = {}
      // console.log(data);
      const res = await this.$axios.post(
        'httpServe/security/config/selectOne',
        param,
        true
      )

      console.log(res.data.forbidAutoRegister, 'res.data.forbidAutoRegister仅允许预录入设备登录')
      this.form = res.data
      console.log(this.$utils.common.decrypt(res.data.defaultPass, ''), '解密')

      this.form.defaultPass = this.$utils.common.decrypt(
        res.data.defaultPass,
        ''
      )
      console.log(this.form.defaultPass, '最终this.form.defaultPass ')
    },
    // 保存
    async securityadd() {
      if (
        this.form.errorNumber > parseInt(999) ||
        this.form.errorNumber < parseInt(0)
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.errorNumberToast'),
          type: 'error',
          offset: 100
        })
        return false
      } else if (
        this.form.serverTimeout > parseInt(999) ||
        this.form.serverTimeout < parseInt(0)
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.serverTimeoutToast'),
          type: 'error',
          offset: 100
        })
        return false
      } else if (
        this.form.clientTimeout > parseInt(999) ||
        this.form.clientTimeout < parseInt(0)
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.clientTimeoutToast'),
          type: 'error',
          offset: 100
        })
        return false
      }
      if (
        this.form.lockTime > parseInt(1440) ||
        this.form.lockTime < parseInt(0)
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.lockTimeToast'),
          type: 'error',
          offset: 100
        })
        return false
      }
      if (
        this.form.devMaxCounts > parseInt(999) ||
        this.form.devMaxCounts < parseInt(0)
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.devMaxCountsToast'),
          type: 'error',
          offset: 100
        })
        return false
      }

      var secretKey = $.getSecretKey('')
      let params = {
        errorNumber: this.form.errorNumber * 1,
        lockTime: this.form.lockTime * 1,
        serverTimeout: this.form.serverTimeout * 1,
        clientTimeout: this.form.clientTimeout * 1,
        // pass:this.$utils.common.encrypt(this.form.pass,"SM4",secretKey),
        // pass: this.form.pass,
        forbidAutoRegister:this.form.forbidAutoRegister,//仅允许预录入设备登录
        allowChangeUser: this.form.allowChangeUser,//允许客户端切换用户
        banDangerIp: this.form.banDangerIp,//增加“风险IP禁止登录”配置入参
        defaultPass: this.$utils.common.encrypt(
          this.form.defaultPass,
          'SM4',
          secretKey
        ),
        devMaxCounts: this.form.devMaxCounts
      }
      console.log(params, '---保存')

      const res = await this.$axios.post(
        '/httpServe/security/config/update',
        params,
        true
      )
      console.log(res)
      // this.titleInfo = "保存成功";
      // this.visibaelFlag = true;
      this.$message({
        message: this.$t('public.SuccessfullySaved'),
        type: 'success',
        offset: 100
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // this.save();
        if (valid) {
          this.securityadd()
        } else {
          console.log('error submit!!')
          return false
        }
      })
      // this.$emit("")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
