<template>
  <div>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationSourceName')+'：'" prop="authName">
        <el-input
          @input="(e) => (form.authName = validForbid(e))"
          v-model.trim="form.authName"
          size="small"
          style="width: 250px"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationSourceType')+'：'">
        <el-select
          v-model="form.sourceType"
          :placeholder="$t('System.SecurityConfigPage.localAuthentication')"
          size="small"
          style="width: 250px"
          @change="change"
          class="location"
        >
          <el-option
            v-for="(item, index) in Certification"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- AD -->
      <div v-if="typeFlag1">
        <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationMethod')">
          <el-select
            disabled
            v-model="form.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="$t(`System.SecurityConfigPage.AuthmethodObj.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.ServerDomainName')" prop="authConfig.ad.domainName">
          <el-input
            v-model="form.authConfig.ad.domainName"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.HttpUrl')" prop="authConfig.ad.ip">
          <el-input
            v-model="form.authConfig.ad.ip"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.Port')+'：'" prop="authConfig.ad.port">
          <el-input
            v-model.trim="form.authConfig.ad.port"
            size="small"
            placeholder="1 ~ 65535"
            style="width: 250px"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('System.SecurityConfigPage.ADDomainImportSettings')"
          style="font-weight: 700; color: #000000"
        ></el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.BaseDN')" prop="authConfig.ad.standardDN">
          <el-input
            v-model="form.authConfig.ad.standardDN"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.AdministratorAccount')" prop="authConfig.ad.account">
          <el-input
            v-model="form.authConfig.ad.account"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.AdministratorPassword')" prop="authConfig.ad.passCode">
          <el-input
            style="width: 250px"
            size="small"
            v-model="form.authConfig.ad.passCode"
            type="password"
          ></el-input>
        </el-form-item>
      </div>

      <!-- LDAP -->
      <!-- <div v-if="typeFlag2"> -->
        <el-form-item v-if="typeFlag2" :label="$t('System.SecurityConfigPage.AuthenticationMethod')">
          <el-select
            disabled
            v-model="form.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="$t(`System.SecurityConfigPage.AuthmethodObj.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="typeFlag2" :label="$t('System.SecurityConfigPage.HttpUrl')" prop="authConfig.ldap.ip">
          <el-input
            v-model="form.authConfig.ldap.ip"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="typeFlag2" :label="$t('public.Port')+'：'" prop="authConfig.ldap.port">
          <el-input
            v-model.trim="form.authConfig.ldap.port"
            size="small"
            placeholder="1 ~ 65535"
            style="width: 250px"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>

        <el-form-item v-if="typeFlag2"
          :label="$t('System.SecurityConfigPage.LDAPQueryObjectProperties')"
          style="font-weight: 700; color: #000000"
        ></el-form-item>
        <el-form-item v-if="typeFlag2" :label="$t('System.SecurityConfigPage.BaseDN')" prop="authConfig.ldap.standardDN">
          <el-input
            v-model="form.authConfig.ldap.standardDN"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="typeFlag2" :label="$t('System.SecurityConfigPage.AdministratorAccount')" prop="authConfig.ldap.account">
          <el-input
            v-model="form.authConfig.ldap.account"
            size="small"
            style="width: 250px"
            placeholder="如：admin"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="typeFlag2" :label="$t('System.SecurityConfigPage.AdministratorPassword')" prop="authConfig.ldap.passCode">
          <el-input
            style="width: 250px"
            size="small"
            v-model="form.authConfig.ldap.passCode"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="typeFlag2"
          :label="$t('System.SecurityConfigPage.UserNameFieldName')"
          prop="authConfig.ldap.accountField"
        >
          <el-input
            v-model="form.authConfig.ldap.accountField"
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
      <!-- </div> -->
      <el-form-item
        label=""
        v-if="
          form.sourceType == 0 || form.sourceType == 1 || form.sourceType == 2
        "
      >
        <el-button
          style="width: 80px"
          type="primary"
          @click="submitForm('form')"
          size="small"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" /> -->
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },
  props: {
    addArray: {
      type: Object,
      default: () => {},
    },
    addArrayId: {
      type: String,
      default: "",
    },
  },
  data() {
    // function validateIP (rule, value, callback)  {
    //   // var IPRegex =
    //   //   /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
    //   // let valdata = value.split("\n");
    //   value.split("\n").forEach((item) => {
    //     if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
    //       callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
    //       return;
    //     }
    //   });
    //   callback();
    // }
    // function validateDomain(rule, value, callback) => {
    //   // var DomainRegex =
    //   //   /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
    //   // let valdata = value.split("\n");
    //   value.split("\n").forEach((item) => {
    //     if (!/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(item)) {
    //       callback(new Error(this.$t('System.SecurityConfigPage.domainNameReg')));
    //       return;
    //     }
    //   });
    //   callback();
    // }
    return {
      visibaelFlag: false, //弹框
      titleInfo: "",
      //   change:"",
      typeFlag1: false,
      typeFlag2: false,
      Certification: [
        // { value: 0, label: "本地认证" },
        { value: 1, label: "Microsoft AD" },
        { value: 2, label: "LDAP" },
      ],
      Authmethod: [
        { value: 0, label: "账密认证" },
        // { value: 1, label: "短信认证" },
      ],
      mixture: [
        { value: 1, label: "Microsoft AD" },
        { value: 2, label: "LDAP" },
      ],
      form: {
        authName: "",
        sourceType: 1,
        authType: 0,
        authConfig: {
          ad: {
            domainName: "",
            ip: "",
            port: "",
            standardDN: "",
            account: "",
            passCode: "",
          },
          ldap: {
            ip: "",
            port: "",
            standardDN: "",
            account: "",
            passCode: "",
            type: "",
            accountField: "",
            passField: "",
          },
        },
      },
      authConfig: {},
      rules: {
        authName: [
          { required: true, message: this.$t('System.SecurityConfigPage.authNameViod'), trigger: "blur" },
          { min: 1, max: 30, message: this.$t('System.SecurityConfigPage.authNameStr'), trigger: "blur" },
        ],
        authConfig: {
          ldap: {
            // 服务器地址
            ip: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.ipViod') },
              { validator:(rule, value, callback) => {
                  // var IPRegex =
                  //   /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
                  // let valdata = value.split("\n");
                  value.split("\n").forEach((item) => {
                    if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                      callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                      return;
                    }
                  });
                  callback();
                }, 
                trigger: "blur" 
              },
            ],
            // 端口
            port: [
              { required: true, message: this.$t('System.SecurityConfigPage.portViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 管理员账户
            account: [
              { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // // 管理员账户
            // account: [
            //   { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
            //   //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            // ],
            // 管理员密码
            passCode: [
              { required: true, message: this.$t('System.SecurityConfigPage.passCodeViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 基准DN
            standardDN: [
              { required: true, message: this.$t('System.SecurityConfigPage.standardDNViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 用户对象类型
            type: [
              {
                required: true,
                message: this.$t('System.SecurityConfigPage.userObjTypeViod'),
                trigger: "blur",
              },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 用户名字段名称
            accountField: [
              {
                required: true,
                message: this.$t('System.SecurityConfigPage.accountFieldViod'),
                trigger: "blur",
              },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 用户密码字段名称：
            // passField: [
            //   {
            //     required: true,
            //     message: "请输入用户密码字段名称",
            //     trigger: "blur",
            //   },
            //   //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            // ],
          },
          ad: {
            // 服务器域名
            domainName: [
              { validator: (rule, value, callback) => {
                  // var DomainRegex =
                  //   /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
                  // let valdata = value.split("\n");
                  value.split("\n").forEach((item) => {
                    if (!/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(item)) {
                      callback(new Error(this.$t('System.SecurityConfigPage.domainNameReg')));
                      return;
                    }
                  });
                  callback();
                }, trigger: "blur" },
              { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.domainNameViod') },
            ],
            // 服务器地址
            ip: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.ipViod') },
              { validator:(rule, value, callback)  =>  {
                  // var IPRegex =
                  //   /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
                  // let valdata = value.split("\n");
                  value.split("\n").forEach((item) => {
                    if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                      callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                      return;
                    }
                  });
                  callback();
                }, 
                trigger: "blur" 
              },
            ],
            // 端口
            port: [
              { required: true, message: this.$t('System.SecurityConfigPage.portViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 基准DN
            standardDN: [
              { required: true, message: this.$t('System.SecurityConfigPage.standardDNViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 管理员账户
            account: [
              { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
            // 管理员密码
            passCode: [
              { required: true, message: this.$t('System.SecurityConfigPage.passCodeViod'), trigger: "blur" },
              //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
            ],
          },
        },
        // 地址
        address: [
          { validator:(rule, value, callback)  =>  {
              // var IPRegex =
              //   /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
              // let valdata = value.split("\n");
              value.split("\n").forEach((item) => {
                if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                  callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                  return;
                }
              });
              callback();
            }, 
            trigger: "blur" 
          },
          { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.addressViod') },
        ],
      },
    };
  },

  mounted() {
    this.SMSlist();
  },
  created() {
    this.IDS = this.addArrayId;
    console.log(this.addArrayId, "this.addArrayId");
    
    if (this.addArray && this.addArray.id) {
      this.form.authName = this.addArray.authName;
      this.form.sourceType = this.addArray.sourceType;
      this.form.authType = Number(this.addArray.authType);
      var authCon = this.addArray.authConfig;
      var authconfig = JSON.parse(authCon);
      this.form.authConfig.ad.domainName = authconfig.domainName;
      this.form.authConfig.ad.ip = authconfig.ip;
      this.form.authConfig.ad.port = Number(authconfig.port);
      this.form.authConfig.ad.standardDN = authconfig.standardDN;
      this.form.authConfig.ad.account = authconfig.account;
      this.form.authConfig.ad.passCode = this.$utils.common.decrypt(
        authconfig.passCode,
        this.form.authConfig.ad.account
      )
      console.log(authconfig.passCode,'this.form.authConfig.ad.passCode');
      this.form.authConfig.ldap.ip = authconfig.ip;
      this.form.authConfig.ldap.port = Number(authconfig.port);
      this.form.authConfig.ldap.standardDN = authconfig.standardDN;
      this.form.authConfig.ldap.account = authconfig.account;
      this.form.authConfig.ldap.passCode = this.$utils.common.decrypt(
        authconfig.passCode,
        this.form.authConfig.ldap.account
      )
      this.form.authConfig.ldap.accountField = authconfig.accountField;
    }
  },
  methods: {
    SMSlist() {
      if (this.form.sourceType == 0) {
        this.typeFlag1 = false;
        this.typeFlag2 = false;
      } else if (this.form.sourceType == 1) {
        this.typeFlag1 = true;
        this.typeFlag2 = false;
      } else if (this.form.sourceType == 2) {
        this.typeFlag1 = false;
        this.typeFlag2 = true;
      }
    },
    change(e) {
      console.log(e, "目前的选中值e");
      if (e == 1) {
        this.typeFlag1 = true;
        this.typeFlag2 = false;
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        }, 0);
        console.log(this.$refs, "jjjhghihjhkjllj");
      } else if (e == 2) {
        this.typeFlag1 = false;
        this.typeFlag2 = true;
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        }, 0);
        console.log(this.$refs, "jjjhghihjhkjllj");
      }

      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      }, 0);
    },
    // 保存
    async smsadd() {
      if (this.IDS) {
        if (this.form.sourceType == 1) {
          if (
            this.form.authConfig.ad.port > parseInt(65535) ||
            this.form.authConfig.ad.port < parseInt(1)
          ) {
            console.log(this.form.authConfig.ad.port, "ad========");
            this.$message({
              message: this.$t('System.SecurityConfigPage.PortRange'),
              type: "error",
              offset: 100,
            });
            return false;
          }
        }
        if (this.form.sourceType == 2) {
          if (
            this.form.authConfig.ldap.port > parseInt(65535) ||
            this.form.authConfig.ldap.port < parseInt(1)
          ) {
            //   alert("ldap");
            console.log(this.form.authConfig.ldap.port, "ldap========");
            this.$message({
              message: this.$t('System.SecurityConfigPage.PortRange'),
              type: "error",
              offset: 100,
            });
            return false;
          }
        }
        var secretKey = $.getSecretKey(this.form.authConfig.ad.account);
        var ldap_secretKey = $.getSecretKey(this.form.authConfig.ldap.account);
        if (this.form.sourceType == 1) {
          console.log(1111111111);
          this.authConfig = {
            domainName: this.form.authConfig.ad.domainName,
            ip: this.form.authConfig.ad.ip,
            port: this.form.authConfig.ad.port,
            standardDN: this.form.authConfig.ad.standardDN,
            account: this.form.authConfig.ad.account,
            passCode: this.$utils.common.encrypt(
              this.form.authConfig.ad.passCode,
              "SM4",
              secretKey
            ),
          };
        } else {
          console.log(22222222);
          this.authConfig = {
            ip: this.form.authConfig.ldap.ip,
            port: this.form.authConfig.ldap.port.toString(),
            standardDN: this.form.authConfig.ldap.standardDN,
            account: this.form.authConfig.ldap.account,
            passCode: this.$utils.common.encrypt(
              this.form.authConfig.ldap.passCode,
              "SM4",
              ldap_secretKey
            ),
            accountField: this.form.authConfig.ldap.accountField,
            passField: "",
          };
        }
        let params = {
          id: this.IDS,
          authName: this.form.authName, //认证源名称
          sourceType: this.form.sourceType, //认证源类型
          authType: this.form.authType.toString(), //认证方式
          authConfig: JSON.stringify(this.authConfig), //认证配置 具体内容
        };
        console.log(params, "---认证源配置保存");
        const res = await this.$axios.post(
          "/httpServe/authSourceConfig/update",
          params,
          true
        );
        console.log(res);
        if (res.data == -1) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.authSourceAlreadyExists'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.$emit("sunline", this.flag);
      } else {
        if (this.form.sourceType == 1) {
          if (
            this.form.authConfig.ad.port > parseInt(65535) ||
            this.form.authConfig.ad.port < parseInt(1)
          ) {
            console.log(this.form.authConfig.ad.port, "ad========");
            this.$message({
              message: this.$t('System.SecurityConfigPage.PortRange'),
              type: "error",
              offset: 100,
            });
            return false;
          }
        }
        if (this.form.sourceType == 2) {
          if (
            this.form.authConfig.ldap.port > parseInt(65535) ||
            this.form.authConfig.ldap.port < parseInt(1)
          ) {
            //   alert("ldap");
            console.log(this.form.authConfig.ldap.port, "ldap========");
            this.$message({
              message: this.$t('System.SecurityConfigPage.PortRange'),
              type: "error",
              offset: 100,
            });
            return false;
          }
        }
        var secretKey = $.getSecretKey(this.form.authConfig.ad.account);
        var ldap_secretKey = $.getSecretKey(this.form.authConfig.ldap.account);
        if (this.form.sourceType == 1) {
          console.log(1111111111);
          this.authConfig = {
            domainName: this.form.authConfig.ad.domainName,
            ip: this.form.authConfig.ad.ip,
            port: this.form.authConfig.ad.port,
            standardDN: this.form.authConfig.ad.standardDN,
            account: this.form.authConfig.ad.account,
            passCode: this.$utils.common.encrypt(
              this.form.authConfig.ad.passCode,
              "SM4",
              secretKey
            ),
          };
        } else {
          console.log(22222222);
          this.authConfig = {
            ip: this.form.authConfig.ldap.ip,
            port: this.form.authConfig.ldap.port.toString(),
            standardDN: this.form.authConfig.ldap.standardDN,
            account: this.form.authConfig.ldap.account,
            passCode: this.$utils.common.encrypt(
              this.form.authConfig.ldap.passCode,
              "SM4",
              ldap_secretKey
            ),
            accountField: this.form.authConfig.ldap.accountField,
            passField: "",
          };
        }
        console.log(this.authConfig, "authConfigauthConfigauthConfig");
        let params = {
          authName: this.form.authName, //认证源名称
          sourceType: this.form.sourceType, //认证源类型
          authType: this.form.authType.toString(), //认证方式
          authConfig: JSON.stringify(this.authConfig), //认证配置 具体内容
        };
        console.log(params, "---认证源配置保存");
        const res = await this.$axios.post(
          "/httpServe/authSourceConfig/insert",
          params,
          true
        );
        console.log(res);
        if (res.data == -1) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.authSourceAlreadyExists'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.$emit("sunline", this.flag);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // this.save();
        if (valid) {
          this.smsadd();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.$emit("")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: #606266;
}
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
