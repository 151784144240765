<template>
  <div>
    <el-form ref="form" :model="form" label-width="200px">
      <el-form-item :label="$t('System.SecurityConfigPage.AppReleaseReview')">
        <el-tooltip placement="right" effect="light">
          <div slot="content" style="color: #807e7e">
            {{ $t("System.SecurityConfigPage.LightReminder") }}
          </div>
          <img
            src="@/assets/question.png"
            alt=""
            srcset=""
            style="padding:10px 0 0 0;"
          />
        </el-tooltip>
        <el-switch
          active-value="1"
          inactive-value="0"
          @change="allowDeletion"
          style="padding:0px 5px 5px 15px;"
          v-model="form.use"
        ></el-switch>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form:{
            use:''
        }
    }
  },
  created() {
    
  },
  mounted() {
    this.selectOne()
  },

  methods: {
    async selectOne() {
        // console.log(data);
        const res = await this.$axios.post(
            '/httpServe/appAudit/config/selectOne',
            {},
            true
        )
        this.$set(this.form,'use',res.data.use+'')
    },
    async allowDeletion(val){
        var param = {
            use:val
        }
        // console.log(data);
        const res = await this.$axios.post(
            '/httpServe/appAudit/config/update',
            param,
            true
        )
    },
    
  },
};
</script>

<style lang="scss" scoped>
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}
::v-deep .el-form-item__label {
    padding: 0 !important;
}
::v-deep .box-corn {
  height: 280px !important;
}
::v-deep .box-wrap {
  height: 330px !important;
}
::v-deep .box-corn .el-form-item {
  margin-bottom: 12px;
}
::v-deep .box-wrap .el-form-item {
  margin-bottom: 12px;
}
</style>
