<template>
  <div>
    <!-- <navi-gation /> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('System.SecurityConfigPage.LoginSecurity')" name="1">
        <Securelogin :key="timer1"></Securelogin>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.SecurityConfigPage.LoginAuthenticaConfig')" name="2">
        <Loginauthentication :key="timer2"></Loginauthentication>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.SecurityConfigPage.AuthenticaSourceConfig')" name="3">
        <Authentication :key="timer3"></Authentication>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.SecurityConfigPage.flowPathConfig')" name="4">
        <Process :key="timer4"></Process>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Securelogin from './components/security/Securel.vue'
import Loginauthentication from './components/security/Loginau.vue'
import Authentication from './components/security/Authen.vue'
import Process from './components/security/Process.vue'
import naviGation from '@/components/hearder/index'
export default {
  components: { Securelogin, Loginauthentication, Authentication,Process,naviGation },
  data() {
    return {
      timer1: '',
      timer2: '',
      timer3: '',
      timer4: '',
      activeName: '1'
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
      } else if (name == 3) {
        this.timer3 = new Date().getTime()
      }else if (name == 4) {
        this.timer4 = new Date().getTime()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>
<style>
.el-input1 {
  width: 200px;
}
.el-input2 {
  width: 200px;
  margin-left: 10px;
}
.btn {
  margin-left: 10px;
  margin-right: 1px;
}
.el-tabs__item.is-active {
  color: #000;
}
.el-button + .el-button {
  margin-left: 0px;
}
</style>