<template>
  <div class="zrgj">
    <router-view></router-view>
    <!-- 认证源配置 -->
    <div class="box" v-if="flag == 1" style="padding-right: 12px">
      <div class="center">
        <el-button type="primary" @click="isflag" class="btn addbtn" size="small">{{ $t("public.New") }}</el-button>
      </div>
      <!-- 列表 -->
      <div style="position: relative">
        <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :data="tableData" row-key="id" lazy size="mini" stripe class="tableClass" tooltip-effect="dark" style="width: 100%" :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" @sort-change="handleSortChange" @selection-change="delobj">
          <!-- <el-table-column type="selection" align="center" width="60"> </el-table-column> -->
          <el-table-column prop="authName" :label="$t('System.SecurityConfigPage.AuthenticationSourceName')" width="150" :show-overflow-tooltip="true" >
          </el-table-column>
          <el-table-column prop="sourceType" :label="$t('System.SecurityConfigPage.AuthenticationSourceType')" width="150" :show-overflow-tooltip="true" >
            <template v-slot="{ row }">
              <template v-if="row.sourceType == 0">{{ $t("System.SecurityConfigPage.localAuthentication") }}
              </template>
              <template v-else-if="row.sourceType == 1">Microsoft AD
              </template>
              <template v-else-if="row.sourceType == 2">LDAP
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="authType" :label="$t('System.SecurityConfigPage.AuthenticationMethods')" width="150" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              <template v-if="row.authType == '0'">{{ $t("System.SecurityConfigPage.AccountSecurityAuthentication") }}
              </template>
              <template v-else-if="row.authType == '1'">{{ $t("System.SecurityConfigPage.SMSAuthentication") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" width="200" :show-overflow-tooltip="true" >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img v-if="scope.row.authName !== $t('System.SecurityConfigPage.MSPPlatformAccountSecret') && scope.row.authName !== $t('System.SecurityConfigPage.MSPPlatformSMS')"  :title="$t('public.Editor')" @click="edit(scope.row)" src="@/assets/icon_write.png" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
              <span class="action_icon">
                <img v-if="scope.row.authName !== $t('System.SecurityConfigPage.MSPPlatformAccountSecret') && scope.row.authName !== $t('System.SecurityConfigPage.MSPPlatformSMS')" :title="$t('public.Delete')"  @click="del(scope.row.id)" src="@/assets/icon_del.png" style="width: 15px; height: 15px; cursor: pointer" />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :page="currentPage4" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
      </div>
    </div>
    <!-- 新增 S6000add -->
    <Authenadd v-if="flag == 2" @sunline="sunline" :addArray="addArray" :addArrayId="addArrayId"></Authenadd>
    <!-- 保存成功的弹框 -->
    <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess" :width="'240px'" />
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
import naviGation from '@/components/hearder/index'
import dialogInfo from '@/components/promptMessage/index'
import Authenadd from './components/Authenadd.vue'
export default {
  components: { pagination, naviGation, dialogInfo, Authenadd },

  data() {
    return {
      id: '',
      name: '',
      name: '',
      memo: '',
      seccess: '',
      addArrayId: '', //新增点击传过去组织树的id
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      searchKey:"",
      searchValue:"",
      orderColume:'updateTime',
      orderRule:'DESC',
      total: 0,
      flag: 1,
      Sencond: 5, //设置初始倒计时
      Successdialog: false, //控制弹出
      isDisabled: false,
      time: null,
      addArray: null,
      tableData: [],
      delarr: [], //删除
      content: [], // 信息类型   转字符串
      page: {
        orderColume: 'lastUpdateTime', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
    }
  },

  watch: {},
  created() {},

  mounted() {
    this.getDataList()
  },

  methods: {
    //监听排序
    handleSortChange(column) {
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.getDataList()
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        // searchKey:this.searchKey,
        // searchValue:this.searchValue,
        orderColume:this.orderColume,
        orderRule:this.orderRule
      }
      // 1为启用    0为禁用
      const res = await this.$axios.post('/httpServe/authSourceConfig/getDataInfo', param, true)
      console.log(res.data.content, '列表数据')
      this.tableData = res.data.content
      console.log(this.tableData)
      this.total = res.data.total
    },
    // 删除
    delobj(obj) {
      this.delarr = obj
    },
    // 删除
    del(id) {
      var parma = { id: id }
      this.$confirm(this.$t('System.SecurityConfigPage.sureDeleteAuthenticationSource'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      }).then(async () => {
        const res = await this.$axios.post(
          '/httpServe/authSourceConfig/delete',
          parma,
          true
        )
        if(res.data==1){
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
        }else if(res.data==0){
          this.$message({
            message: this.$t('System.SecurityConfigPage.SaveFailed'),
            type: 'error',
            offset: 100
          })
        }else if(res.data==-1){
          this.$message({
            message: this.$t('System.SecurityConfigPage.DeleteNotAllowed'),
            type: 'error',
            offset: 100
          })
        }
        this.getDataList()
      })
    },
    sunline(data) {
      if (data == 1) {
        this.getDataList()
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
      }
      this.flag = data
    },
    handleClose() {
      this.Successdialog = false //提示消息  关闭
    },
    determine() {
      this.Successdialog = false //提示消息  关闭
    }, // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page
      this.pageSize = limit
      // 调用查询方法
      this.getDataList()
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.getDataList()
    },
    // 新增
    isflag() {
      this.flag = 2
      this.addArray = null
      this.addArrayId = ''
    },
    // 编辑
    edit(val) {
      console.log(val,"-----编辑数据-------");
      this.addArray = val
      this.addArrayId = val.id+''
      this.flag = 2
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    checkChange(selection, row) {
      selection.forEach((item) => {
        this.delarr.push(item.id)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection()
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.tableData.toggleRowSelection(row)
        })
      } else {
        this.$refs.tableData.clearSelection()
      }
      this.isCheckShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
.zrgj {
  width: 100%;
  height: 100%;
}
.el-input1 {
  width: 200px;
  margin-left: 1px;
}
.btn {
  margin-left: 1px;
  background: #4787de;
  margin-right: 1px;
}
.searchbtn {
  margin-left: 4px;
}
.center {
  margin: 10px 0;
  justify-content: flex-start;
}
.save {
  margin: 4px 0 102px 200px;
}
.el-form {
  margin-top: 25px;
}
.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}
.box {
  margin-left: 10px;
  margin-top: 10px;
}
.el-input {
  width: 50%;
}
#text {
  resize: none;
}
span >>> .el-input > input {
  height: 24px;
}
.el-form-item__content {
  width: 78%;
  display: flex;
  margin: 0 !important;
}
// #success{
//   // height: 100px;
// }

.el-button + .el-button {
  margin-left: 0px;
}
.el-checkbox {
  margin-right: 10px;
}
.el-radio,
.el-radio__input {
  margin-left: 10px;
}
.el-checkbox,
.el-checkbox__input {
  margin-left: 3px;
}
.el-textarea__inner {
  resize: none;
}
.el-select .el-input {
  width: 120px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-checkbox:last-of-type {
  margin-left: 12px;
}
.el-form {
  width: 848px;
}
// .el-form-item {
//   margin-bottom: 5px;
// }
.el-form[data-v-42c2d864] {
  margin-top: 10px;
}
.el-form-item__content {
  width: 662px;
  display: flex;
  margin: 0 !important;
}
.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  //height: 100px;
  overflow: auto;
  margin-right: 12px;
}
// .center {
//   width: 100%;
// margin-left: 10px;
// margin-right: 10px;
// margin-top: 10px;
/* display: flex; */
/* justify-content: space-between; */
//   justify-content: flex-start;
// }
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  height: 14px;
  line-height: 20px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.el-form-item__content {
  line-height: 36px;
  position: relative;
  font-size: 14px;
}
.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}
</style>
<style lang="scss">
.save[data-v-42c2d864] {
  margin: 21px 0 102px 200px !important;
  margin-bottom: 110px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}

</style>
