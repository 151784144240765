<template>
  <div>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <!-- 认证方式 -->
      <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationType')">
        <el-select
          v-model="form.type"
          :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
          size="small"
          style="width: 250px"
          @change="change"
          class="location"
        >
          <el-option
            v-for="(item, index) in Certification"
            :key="index"
            :label="$t(`System.SecurityConfigPage.CertificationObj.${item.label}`)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 账密认证 -->
      <template v-if="typeFlag1">
        <el-form-item  :label="$t('System.SecurityConfigPage.AuthenticationMethod')">
          <el-select
            disabled
            v-model="form.auth.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="$t(`System.SecurityConfigPage.AuthmethodObj.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 认证源 -->
        <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationSource')">
          <el-select
            v-model="form.auth.sources"
            size="small"
            style="width: 250px"
            @change="changeSources"
            class="location"
          >
            <el-option
              v-for="item in authSourceName"
              :key="item.id"
              :label="item.authName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>

      <!-- 混合认证 -->
      <template v-if="self_certification">
        <!-- //认证方式1 -->
        <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationMethodOne')"> 
          <el-select
            disabled
            v-model="form.auth.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="$t(`System.SecurityConfigPage.AuthmethodObj.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationSourceOne')">
          <el-select
            disabled
            v-model="form.auth.msp"
            :placeholder="$t('System.SecurityConfigPage.MSPPlatformAccountSecret')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="item in mspArr"
              :key="item.id"
              :label="$t(`System.SecurityConfigPage.mspArr.${item.label}`)"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="认证方式2：">
          <el-select
            disabled
            v-model="form.auth.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('System.SecurityConfigPage.AuthenticationSourceTwo')">
          <el-select
            @change="mixtureChange"
            v-model="form.auth.sources2"
            placeholder=""
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="item in mixture"
              :key="item.id"
              :label="item.authName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>

      <!-- 二次认证 -->
      <template v-if="typeFlag2">
        <el-form-item :label="$t('System.SecurityConfigPage.MainAuthenticationMethod')">
          <el-select
            disabled
            v-model="form.auth.authType"
            :placeholder="$t('System.SecurityConfigPage.AccountSecretAuthentication')"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in Authmethod"
              :key="index"
              :label="$t(`System.SecurityConfigPage.AuthmethodObj.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.MasterAuthenticationSource')">
          <el-select
            v-model="form.auth.twoFactor"
            size="small"
            style="width: 250px"
            @change="twochangeSou"
            class="location"
          >
            <el-option
              v-for="item in twoauth"
              :key="item.id"
              :label="item.authName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 认证方式2 -->
        <!-- 二次认证认证方式： -->
        <el-form-item :label="$t('System.SecurityConfigPage.SecondaryAuthenticationMethod')">
          <el-select
            @change="twotureChange"
            v-model="form.auth.msgType"
            placeholder="短信认证"
            size="small"
            style="width: 250px"
            class="location"
          >
            <el-option
              v-for="(item, index) in SMSauth"
              :key="index"
              :label="$t(`System.SecurityConfigPage.SMSauth.${item.label}`)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 二次认证认证源： -->
        <el-form-item :label="$t('System.SecurityConfigPage.SecondaryAuthenticationSource')" v-if="twoSourcs">
          <el-select
            v-model="form.auth.twoSources"
            size="small"
            style="width: 250px"
            @change="twoSourc"
            class="location"
          >
            <el-option
              v-for="item in authNames"
              :key="item.id"
              :label="item.authName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('System.SecurityConfigPage.CertificationRules')" prop="content">
          <p style="color: #ccc">{{ $t("System.SecurityConfigPage.RulesPlaceholder") }}</p>
          <el-checkbox-group v-model="content">
            <p>
              <el-checkbox value="1" label="1">{{ $t("System.SecurityConfigPage.EveryLogin") }}</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{ $t("System.SecurityConfigPage.EveryLoginTooltipFront") }}<br />{{ $t("System.SecurityConfigPage.EveryLoginTooltipAfter") }}
                  <!-- <br />三方SDK行为信息，并在SDK行为<br />列表展示 -->
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <p>
              <el-checkbox value="2" label="2">{{ $t("System.SecurityConfigPage.FirstLogin") }}</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{ $t("System.SecurityConfigPage.FirstLoginFront") }}<br />{{ $t("System.SecurityConfigPage.FirstLoginAfter") }}
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <p>
              <el-checkbox value="3" label="3">{{ $t("System.SecurityConfigPage.NewDeviceLogin") }}</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{ $t("System.SecurityConfigPage.NewDeviceLoginFront") }}<br />{{ $t("System.SecurityConfigPage.NewDeviceLoginAfter") }}
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <p>
              <el-checkbox value="4" label="4">非上次使用设备登录</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  用户登录设备非上次使用设备触发二<br />次{{ $t("System.SecurityConfigPage.NewDeviceLoginAfter") }}
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <p>
              <el-checkbox value="5" label="5">{{ $t("System.SecurityConfigPage.RiskIPLogin") }}</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{ $t("System.SecurityConfigPage.RiskIPLoginFront") }}<br />{{ $t("System.SecurityConfigPage.RiskIPLogincenter") }}<br />{{ $t("System.SecurityConfigPage.RiskIPLoginAfter") }}
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <p>
              <el-checkbox value="6" label="6">{{ $t("System.SecurityConfigPage.AbnormalTimePeriodLogin") }}</el-checkbox>
              <el-tooltip placement="right" effect="light">
                <div slot="content" style="color: #807e7e">
                  {{ $t("System.SecurityConfigPage.AbnormalTimePeriodLoginFront") }}<br />{{ $t("System.SecurityConfigPage.AbnormalTimePeriodLogincenter") }}<br />{{ $t("System.SecurityConfigPage.AbnormalTimePeriodLoginAfter") }}
                </div>
                <img
                  src="../../../../assets/question.png"
                  alt=""
                  srcset=""
                  style="padding-left: 8px"
                />
              </el-tooltip>
            </p>
            <!-- <p>
              <el-checkbox style="width: 22%" value="6" label="6">网关访问日志</el-checkbox>
            </p> -->
          </el-checkbox-group>
          <p>
            <span
              style="
                color: blue;
                text-decoration: underline;
                cursor: pointer;
                padding-left: 25px;
              "
              @click="conTime"
              >{{ $t("System.SecurityConfigPage.ConfigAbnormalTimePeriod") }}</span
            >
          </p>
        </el-form-item>
      </template>

      <el-form-item label="">
        <el-button
          style="width: 80px"
          type="primary"
          @click="submitForm('form')"
          size="small"
          >{{ $t("public.Save") }}</el-button
        >
        <!-- <el-button type="primary" @click="SMStest" size="small" style="margin-left: 5px"
          >测试</el-button
        > -->
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 弹窗展示异常时间配置 -->
    <Eldialog
      class="dialog"
      @handleClose="handleCloseConTime"
      :title="$t('System.SecurityConfigPage.AbnormalTimePeriod')"
      :flagbtn="flagbtn"
      :btnTitle="btnTitle"
      :determineBtn="determineBtn"
      :cancelBtn="cancelBtn"
      @determine="violatConTime"
      :dialogVisible="conTimeVisible"
      :width="'560px'"
      :num="'231px'"
      v-if="conTimeVisible"
    >
      <div class="box-corn" style="padding: 25px 0px 0px 0px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('System.SecurityConfigPage.timeFrame')">
            <el-radio-group v-model="ruleForm.valid" @change="changeEvent">
              <el-radio label="0">{{ $t("System.SecurityConfigPage.PermanentlyValid") }}</el-radio>
              <el-radio label="1">{{ $t("System.SecurityConfigPage.custom") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="this.ruleForm.valid == '1'">
            <el-date-picker :clearable="false"
              style="width: 130px"
              v-model="ruleForm.start"
              type="date"
              :placeholder="$t('System.SecurityConfigPage.SelectAStartTime')"
              size="small"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            -
            <el-date-picker :clearable="false"
              style="width: 130px"
              v-model="ruleForm.end"
              type="date"
              :placeholder="$t('System.SecurityConfigPage.SelectAEndTime')"
              size="small"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('System.SecurityConfigPage.cycleTime')" v-if="this.ruleForm.valid == '0'">
            <el-checkbox-group v-model="ruleForm.weekdays">
              <el-checkbox label="1" name="type">{{ $t("System.SecurityConfigPage.Monday") }}</el-checkbox>
              <el-checkbox label="2" name="type">{{ $t("System.SecurityConfigPage.Tuesday") }}</el-checkbox>
              <el-checkbox label="3" name="type">{{ $t("System.SecurityConfigPage.Wednesday") }}</el-checkbox>
              <el-checkbox label="4" name="type">{{ $t("System.SecurityConfigPage.Thursday") }}</el-checkbox>
              <el-checkbox label="5" name="type">{{ $t("System.SecurityConfigPage.Friday") }}</el-checkbox>
              <el-checkbox label="6" name="type">{{ $t("System.SecurityConfigPage.Saturday") }}</el-checkbox>
              <el-checkbox label="7" name="type">{{ $t("System.SecurityConfigPage.Sunday") }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('System.SecurityConfigPage.regularTime')" v-if="this.ruleForm.valid == '0'">
            <el-select
              v-model="ruleForm.timeStart"
              size="small"
              style="width: 112px"
             :placeholder="$t('System.SecurityConfigPage.SelectTimeZone')"
            >
              <el-option
                v-for="(item, index) in optionsDay"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            -
            <el-select
              v-model="ruleForm.timeEnd"
              size="small"
              style="width: 112px"
             :placeholder="$t('System.SecurityConfigPage.SelectTimeZone')"
            >
              <el-option
                v-for="(item, index) in optionsDay"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('System.SecurityConfigPage.cycleTime')" v-if="this.ruleForm.valid == '1'">
            <el-checkbox-group v-model="ruleForm.weekdays1">
              <el-checkbox label="1" name="type">{{ $t("System.SecurityConfigPage.Monday") }}</el-checkbox>
              <el-checkbox label="2" name="type">{{ $t("System.SecurityConfigPage.Tuesday") }}</el-checkbox>
              <el-checkbox label="3" name="type">{{ $t("System.SecurityConfigPage.Wednesday") }}</el-checkbox>
              <el-checkbox label="4" name="type">{{ $t("System.SecurityConfigPage.Thursday") }}</el-checkbox>
              <el-checkbox label="5" name="type">{{ $t("System.SecurityConfigPage.Friday") }}</el-checkbox>
              <el-checkbox label="6" name="type">{{ $t("System.SecurityConfigPage.Saturday") }}</el-checkbox>
              <el-checkbox label="7" name="type">{{ $t("System.SecurityConfigPage.Sunday") }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('System.SecurityConfigPage.regularTime')" v-if="this.ruleForm.valid == '1'">
            <el-select
              v-model="ruleForm.timeStart1"
              size="small"
              style="width: 112px"
             :placeholder="$t('System.SecurityConfigPage.SelectTimeZone')"
            >
              <el-option
                v-for="(item, index) in optionsDay"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            -
            <el-select
              v-model="ruleForm.timeEnd1"
              size="small"
              style="width: 112px"
             :placeholder="$t('System.SecurityConfigPage.SelectTimeZone')"
            >
              <el-option
                v-for="(item, index) in optionsDay"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="flagbtn" slot="footer" class="diailog-footer">
        <el-button
          v-if="determineBtn"
          size="small"
          type="primary"
          class="btn-determine"
          @click="violatConTime"
          >{{ btnTitle }}</el-button
        >
        <el-button
          v-if="cancelBtn"
          size="small"
          type="primary"
          class="btn-cancel"
          @click="cancel"
          >{{ $t("public.Cancel") }}</el-button
        >
      </div>
    </Eldialog>
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog"; //弹框
import { log } from "console";
export default {
  components: { dialogInfo, Eldialog },
  data() {
    // function validateIP(rule, value, callback) {
    //   var IPRegex =
    //     /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/;
    //   let valdata = value.split("\n");
    //   valdata.forEach((item) => {
    //     if (!IPRegex.test(item)) {
    //       callback(new Error("请输入正确的IP地址,如：192.168.0.1"));
    //       return;
    //     }
    //   });
    //   callback();
    // }
    // function validateDomain(rule, value, callback) {
    //   var DomainRegex =
    //     /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
    //   let valdata = value.split("\n");
    //   valdata.forEach((item) => {
    //     if (!DomainRegex.test(item)) {
    //       callback(new Error("请填写一个域名，如：cloud.tencent.com"));
    //       return;
    //     }
    //   });
    //   callback();
    // }
    return {
      platformFlag: 0,
      platformID: null, //MSP平台账密id
      num: "231px", //弹框默认高度
      btnTitle: "确定",
      flagbtn: true,
      cancelBtn: true,
      determineBtn: true,
      conTimeVisible: false, //新加异常时间配置弹框默认false 不展示
      dataObj: {},
      visibaelFlag: false, //弹框
      titleInfo: "",
      self_certification: false,
      typeFlag1: true,
      typeFlag2: false,
      currentPage4: 1, //当前页
      pageSize: 9999, //每页显示条数
      orderColume: "updateTime",
      orderRule: "DESC",
      fliterSources2: [],
      form: {
        type: 0, // 0-账密认证 1-混合认证 2-二次认证
        auth: {
          authType: 0, //0-账密认证---认证方式  混合认证---认证方式1/认证方式2  二次认证---认证方式1
          sources: 0, //0-账密认证---认证源名称   二次认证---认证源名称1
          msp: "", //混合认证 认证源名称1
          sources2: "", //混合认证 认证源名称2
          msgType: "1", //二次认证 认证方式2
          twoFactor: 0, //二次认证 认证源名称1
          twoSources: 0, //二次认证 认证源名称2
        },
      },
      Certification: [
        { value: 0, label: "账密认证" },
        { value: 1, label: "混合认证" },
        { value: 2, label: "二次认证" },
      ],
      Authmethod: [
        { value: 0, label: "账密认证" },
        // { value: '1', label: "短信认证" },
      ],
      // 认证源名称
      authSourceName: [
        // { value: 1, label: "MSP平台账密" },
      ],
      // 双因素认证认证源名称1
      twoauth: [
        // { value: 1, label: "MSP平台账密" },
      ],
      // 双因素认证认证源名称2
      authNames: [
        // { value: 1, label: "MSP平台账密" },
      ],
      newEventData: [],
      // 混合认证 认证源名称2
      newmixtureData: [],
      // 混合认证 认证方式2
      mixture: [
        // { value: 1, label: "Microsoft AD" },
        // { value: 2, label: "LDAP" },
      ],
      // 混合认证认证源名称1
      mspArr: [{ id: 2, label: "MSP平台账密" }],
      // 双因素认证 认证方式2
      SMSauth: [
        { value: "1", label: "短信认证" },
        { value: "4", label: "生物认证" },
      ],
      twoSourcs:true,
      content: [],
      ruleForm: {
        weekdays: [],
        valid: "0",
        timeStart: "00:00",
        timeEnd: "24:00",
        start: "",
        end: "",
        weekdays1: [],
        timeStart1: "00:00",
        timeEnd1: "24:00",
      },
      // flagStatus: false,
      optionsDay: [
        {
          label: "00:00",
          value: "00:00",
        },
        {
          label: "00:30",
          value: "00:30",
        },
        {
          label: "01:00",
          value: "01:00",
        },
        {
          label: "01:30",
          value: "01:30",
        },
        {
          label: "02:00",
          value: "02:00",
        },
        {
          label: "02:30",
          value: "02:30",
        },
        {
          label: "03:00",
          value: "03:00",
        },
        {
          label: "03:30",
          value: "03:30",
        },
        {
          label: "04:00",
          value: "04:00",
        },
        {
          label: "04:30",
          value: "04:30",
        },
        {
          label: "05:00",
          value: "05:00",
        },
        {
          label: "05:30",
          value: "05:30",
        },
        {
          label: "06:00",
          value: "06:00",
        },
        {
          label: "06:30",
          value: "06:30",
        },
        {
          label: "07:00",
          value: "07:00",
        },
        {
          label: "07:30",
          value: "07:30",
        },
        {
          label: "08:00",
          value: "08:00",
        },
        {
          label: "08:30",
          value: "08:30",
        },
        {
          label: "09:00",
          value: "09:00",
        },
        {
          label: "09:30",
          value: "09:30",
        },
        {
          label: "10:00",
          value: "10:00",
        },
        {
          label: "10:30",
          value: "10:30",
        },
        {
          label: "11:00",
          value: "11:00",
        },
        {
          label: "11:30",
          value: "11:30",
        },
        {
          label: "12:00",
          value: "12:00",
        },
        {
          label: "12:30",
          value: "12:30",
        },
        {
          label: "13:00",
          value: "13:00",
        },
        {
          label: "13:30",
          value: "13:30",
        },
        {
          label: "14:00",
          value: "14:00",
        },
        {
          label: "14:30",
          value: "14:30",
        },
        {
          label: "15:00",
          value: "15:00",
        },
        {
          label: "15:30",
          value: "15:30",
        },
        {
          label: "16:00",
          value: "16:00",
        },
        {
          label: "16:30",
          value: "16:30",
        },
        {
          label: "17:00",
          value: "17:00",
        },
        {
          label: "17:30",
          value: "17:30",
        },
        {
          label: "18:00",
          value: "18:00",
        },
        {
          label: "18:30",
          value: "18:30",
        },
        {
          label: "19:00",
          value: "19:00",
        },
        {
          label: "19:30",
          value: "19:30",
        },
        {
          label: "20:00",
          value: "20:00",
        },
        {
          label: "20:30",
          value: "20:30",
        },
        {
          label: "21:00",
          value: "21:00",
        },
        {
          label: "21:30",
          value: "21:30",
        },
        {
          label: "22:00",
          value: "22:00",
        },
        {
          label: "22:30",
          value: "22:30",
        },
        {
          label: "23:00",
          value: "23:00",
        },
        {
          label: "23:30",
          value: "23:30",
        },
        {
          label: "24:00",
          value: "24:00",
        },
      ],
      rules: {
        ad: {
          // 服务器域名
          domainName: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.domainNameViod') },
            { validator: (rule, value, callback)=> {
              // var DomainRegex =
              //   /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
              // let valdata = value.split("\n");
              value.split("\n").forEach((item) => {
                if (!/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(item)) {
                  callback(new Error(this.$t('System.SecurityConfigPage.domainNameReg')));
                  return;
                }
              });
              callback();
            },
             trigger: "blur" 
            },
          ],
          // 服务器地址
          ip: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.ipViod') },
            { validator: (rule, value, callback)=> {
                // let valdata = value.split("\n");
                value.split("\n").forEach((item) => {
                  if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                    callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                    return;
                  }
                });
                callback();
              }, 
              trigger: "blur" 
            }
          ],
          // 端口
          port: [
            { required: true, message: this.$t('System.SecurityConfigPage.portViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 基准DN
          standardDN: [
            { required: true, message: this.$t('System.SecurityConfigPage.standardDNViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 管理员账户
          account: [
            { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 管理员密码
          passCode: [
            { required: true, message: this.$t('System.SecurityConfigPage.passCodeViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
        },
        ldap: {
          // 服务器域名
          domainName: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.domainNameViod') },
            { validator:  (rule, value, callback)=> {
              // var DomainRegex =
              //   /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
              // let valdata = value.split("\n");
              value.split("\n").forEach((item) => {
                if (!/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(item)) {
                  callback(new Error(this.$t('System.SecurityConfigPage.domainNameReg')));
                  return;
                }
              });
              callback();
            },
             trigger: "blur" 
            }
          ],
          // 服务器地址
          ip: [
            { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.ipViod') },
            { validator: (rule, value, callback)=> {
                // let valdata = value.split("\n");
                value.split("\n").forEach((item) => {
                  if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                    callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                    return;
                  }
                });
                callback();
              }, 
              trigger: "blur"
            }
          ],
          // 端口
          port: [
            { required: true, message: this.$t('System.SecurityConfigPage.portViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 基准DN
          account: [
            { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // // 管理员账户
          // account: [
          //   { required: true, message: this.$t('System.SecurityConfigPage.accountViod'), trigger: "blur" },
          //   //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          // ],
          // 管理员密码
          passCode: [
            { required: true, message: this.$t('System.SecurityConfigPage.passCodeViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 基准DN
          standardDN: [
            { required: true, message: this.$t('System.SecurityConfigPage.standardDNViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 用户对象类型
          type: [
            { required: true, message: this.$t('System.SecurityConfigPage.userObjTypeViod'), trigger: "blur" },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 用户名字段名称
          accountField: [
            {
              required: true,
              message: this.$t('System.SecurityConfigPage.accountFieldViod'),
              trigger: "blur",
            },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
          // 用户密码字段名称：
          passField: [
            {
              required: true,
              message: this.$t('System.SecurityConfigPage.passFieldViod'),
              trigger: "blur",
            },
            //   { min: 1, max: 65535, message: "最长输入5个字符", trigger: "blur" },
          ],
        },
        // 地址
        address: [
          { required: true, trigger: "blur", message: this.$t('System.SecurityConfigPage.addressViod') },
          { validator: (rule, value, callback)=> {
              // let valdata = value.split("\n");
              value.split("\n").forEach((item) => {
                if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|[1-9])\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))\.((([0-9]|([1-9]\d)|(1\d\d)|(2([0-4]\d|5[0-5])))))$/.test(item)) {
                  callback(new Error(this.$t('System.SecurityConfigPage.domainNameViod')));
                  return;
                }
              });
              callback();
            }, 
            trigger: "blur"
          },
        ],
        content: [{ required: true, trigger: "blur", message: "" }],
        // start:[{ required: true, trigger: "blur", message: "请先配置时间范围" }]
      },
    };
  },
  created() {
    
  },
  mounted() {
    this.getDataList();
    this.SMSlist();
    this.ruleForm.start = this.$common.getDate();
    this.ruleForm.end = this.$common.getAnyMonth("-", +1);
  },

  methods: {
    // 获取所有内容数据
    async getDataList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      const res = await this.$axios.post(
        "/httpServe/authSourceConfig/getDataInfo",
        param,
        true
      );
      res.data.content.forEach((item) => {
        if (item.authName === "MSP平台账密") {
          this.platformID = item.id; //二次认证-主认证认证源回显默认----MSP平台账密
        }
      });
      //账密认证
      this.authSourceName = res.data.content.filter((i) => {
        return i.authType != "1";
      });
      //混合认证
      this.mixture = res.data.content.filter((i) => {
        return i.sourceType != "0";
      });
      this.form.auth.msp = this.mspArr[0].id;
      //二次认证--主认证认证源
      this.twoauth = res.data.content.filter((i) => {
        return i.authType != "1";
      });
      //二次认证--二次认证认证源
      this.authNames = res.data.content.filter((i) => {
        return i.authType != "0";
      });
      this.form.auth.twoSources = this.authNames[0].id;
    },
    // 进入页面接口请求
    async SMSlist() {
      var param = {};
      const res = await this.$axios.post(
        "httpServe/auth/selectOne",
        param,
        true
      );
      console.log(this.form.type, "this.form.type == 2");
      this.dataObj = res.data;
      this.form.type = res.data.type;
      if (this.form.type === 0) {
        this.typeFlag1 = true;
        this.typeFlag2 = false;
        this.self_certification = false;
        if (res.data.auth[0]) {
          this.form.auth.sources = res.data.auth[0].sources[0];
        }
      } else if (this.form.type === 1) {
        this.typeFlag1 = false;
        this.typeFlag2 = false;
        this.self_certification = true;
        if (res.data.auth[0].sources[1]) {
          this.form.auth.sources2 =this.$store.state.sourcesFlag == 1 ? this.mixture[0].id : res.data.auth[0].sources[1]; //混合认证--认证源2
        }
      } else if (this.form.type === 2) {
        this.typeFlag1 = false;
        this.typeFlag2 = true;
        this.self_certification = false;
        if (res.data.auth[0].sources[0]) {
          this.form.auth.twoFactor =this.$store.state.platform== 1 ? this.mspArr[0].id : res.data.auth[0].sources[0];
        }
      }
      if (res.data.auth[1]) {
        this.form.auth.msgType = res.data.auth[1]
          ? res.data.auth[1].authType
          : this.form.auth.msgType;
        this.form.auth.twoSources = res.data.auth[1]
          ? res.data.auth[1].sources[0]
          : this.authNames[0].id;
        this.content = res.data.auth[1]
          ? res.data.auth[1].rule.map(String)
          : [];
        this.ruleForm.valid = res.data.auth[1]
          ? res.data.auth[1].errTime.valid
          : "";
        if (res.data.auth[1].errTime.valid == "1") {
          // this.flagStatus = true
          this.ruleForm.weekdays1 = res.data.auth[1].errTime.weekdays
            .split(",")
            .map(String);
          this.ruleForm.timeStart1 = res.data.auth[1].errTime.timeStart;
          this.ruleForm.timeEnd1 = res.data.auth[1].errTime.timeEnd;
          // -----------------------------------------------------------------------
          this.ruleForm.weekdays = [];
          this.ruleForm.timeStart = "00:00";
          this.ruleForm.timeEnd = "24:00";
          this.ruleForm.start = res.data.auth[1].errTime.start;
          this.ruleForm.end = res.data.auth[1].errTime.end;
        }
        if (res.data.auth[1].errTime.valid == "0") {
          this.ruleForm.weekdays = res.data.auth[1].errTime.weekdays
            .split(",")
            .map(String);
          this.ruleForm.timeStart = res.data.auth[1].errTime.timeStart;
          this.ruleForm.timeEnd = res.data.auth[1].errTime.timeEnd;
          // -----------------------------------------------------------------------
          this.ruleForm.weekdays1 = [];
          this.ruleForm.timeStart1 = "00:00";
          this.ruleForm.timeEnd1 = "24:00";
          this.ruleForm.start = this.$common.getDate();
          this.ruleForm.end = this.$common.getAnyMonth("-", +1);
        }
        if(res.data.auth[1].authType==4){
          this.twoSourcs=false;
        }else if(res.data.auth[1].authType!=4){
          this.twoSourcs=true;
        }
      }
    },
    //
    change(e) {
      console.log(this.$store.state.platformFlag, "目前的选中值e");
      if (e == 0) {
        this.typeFlag1 = true;
        this.typeFlag2 = false;
        this.self_certification = false;
        this.form.auth.sources = this.platformID;
        return
      } else if (e == 1) {
        this.typeFlag1 = false;
        this.typeFlag2 = false;
        this.self_certification = true;
        this.form.auth.msp = this.mspArr[0].id;
        if(this.$store.state.sourcesFlag === '2'){
          this.form.auth.sources2 =this.dataObj.auth[0].sources[1];//混合认证--认证源2
        }else {
          this.form.auth.sources2 =this.mixture[0].id; //混合认证--认证源2
        }
      } else if (e == 2) {
        this.typeFlag2 = true;
        this.typeFlag1 = false;
        this.self_certification = false;
        if(this.$store.state.platform=== '2'){
          console.log(99998,'yf7787y787');
          this.form.auth.twoFactor =this.dataObj.auth[0].sources[0];//二次认证--主认证认证源
        }else if(this.$store.state.platform=== '1'){
          this.form.auth.twoFactor = this.mspArr[0].id; //二次认证--主认证认证源
        }else{
          this.form.auth.twoFactor = this.mspArr[0].id; //二次认证--主认证认证源
        }
      }
    },
    //changeSources
    changeSources(e) {
      this.form.auth.sources = e;
    },
    Changeaut(e) {
    },
    twotureChange(e) {
      console.log(e,'eeeeee');
      this.form.auth.msgType = e;
      if(e==4){
        this.twoSourcs=false;
      }else if(e!=4){
        this.twoSourcs=true;
        this.form.auth.twoSources=this.authNames[0].id
      }
    },
    mixtureChange(e) {
      // console.log(e, "目前的选中值认证源名称e");
      this.form.auth.sources2 = e;
    },
    twochangeSou(e) {
      // console.log(e, "目前的选中值主认证源名称e");
      this.form.auth.twoFactor = e;
    },
    twoSourc(e) {
      // console.log(e, "目前的选中值认证源名称e");
      this.form.auth.twoSources = e;
    },
    //确定保存上异常时间配置项
    violatConTime() {
      if (
        new Date(this.ruleForm.start).getTime() >
        new Date(this.ruleForm.end).getTime()
      ) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.startGreaterEndToast'),
          type: "error",
          offset: 100,
        });
        return false;
      }
      if (this.ruleForm.valid === "0") {
        if (this.ruleForm.timeStart >= this.ruleForm.timeEnd) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.StartGreaterEqual'),
            type: "error",
            offset: 100,
          });
          return false;
        }
      }
      if (this.ruleForm.valid === "1") {
        if (this.ruleForm.timeStart1 >= this.ruleForm.timeEnd1) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.StartGreaterEqual'),
            type: "error",
            offset: 100,
          });
          return false;
        }
      }
      if (this.ruleForm.start == null && this.ruleForm.end != null) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.startCannotEmptyToast'),
          type: "error",
          offset: 100,
        });
        return false;
      }
      if (this.ruleForm.start != null && this.ruleForm.end == null) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.endCannotEmptyToast'),
          type: "error",
          offset: 100,
        });
        return false;
      }
      if (this.ruleForm.start == null && this.ruleForm.end == null) {
        this.$message({
          message: this.$t('System.SecurityConfigPage.CannotEmpty'),
          type: "error",
          offset: 100,
        });
        return false;
      }
      if (this.ruleForm.start == null || this.ruleForm.end == null) {
        this.ruleForm.start = this.$common.getDate();
        this.ruleForm.end = this.$common.getAnyMonth("-", +1);
      }
      const perObj = JSON.stringify(this.ruleForm);
      window.sessionStorage.setItem("perObj", perObj);

      this.conTimeVisible = false; //弹框关闭
    },
    changeEvent(val) {
      const infoPer = JSON.parse(window.sessionStorage.getItem("perObj"));
      if (val == 1) {
        if (infoPer) {
          this.ruleForm.weekdays1 = infoPer.weekdays
            ? infoPer.weekdays.split(",").map(String)
            : [];
          this.ruleForm.timeStart1 = infoPer.timeStart1;
          this.ruleForm.timeEnd1 = infoPer.timeEnd1;
          this.ruleForm.weekdays = [];
          this.ruleForm.timeStart = "00:00";
          this.ruleForm.timeEnd = "24:00";
          this.ruleForm.start =
            infoPer.start == null ? this.$common.getDate() : infoPer.start;
          this.ruleForm.end =
            infoPer.end == null
              ? this.$common.getAnyMonth("-", +1)
              : infoPer.end;
        } else {
          this.ruleForm.start = this.$common.getDate();
          this.ruleForm.end = this.$common.getAnyMonth("-", +1);
          return;
        }
      } else {
        if (infoPer) {
          this.ruleForm.weekdays = infoPer.weekdays
            ? infoPer.weekdays.split(",").map(String)
            : [];
          this.ruleForm.timeStart = infoPer.timeStart;
          this.ruleForm.timeEnd = infoPer.timeEnd;
          this.ruleForm.weekdays1 = [];
          this.ruleForm.timeStart1 = infoPer.timeStart1;
          this.ruleForm.timeEnd1 = infoPer.timeEnd1;
          this.ruleForm.start = this.$common.getDate();
          this.ruleForm.end = this.$common.getAnyMonth("-", +1);
        } else {
          this.ruleForm.start = this.$common.getDate();
          this.ruleForm.end = this.$common.getAnyMonth("-", +1);
          return;
        }
      }
    },

    // 保存
    async smsadd() {
      if (this.form.type == 0) {
        let params = {
          type: this.form.type, // 0-账密认证 1-混合认证 2-双因素认证
          auth: [
            {
              authType: this.form.auth.authType + "", //0-账密认证 1-短信认证
              sources: [this.form.auth.sources],
            },
          ],
        };
        const res = await this.$axios.post(
          "/httpServe/auth/update",
          params,
          true
        );0
        this.$store.commit('platformFlag','1')
        this.$store.commit('sourcesTwoFlag','1')

      } else if (this.form.type == 1) {
        let params = {
          type: this.form.type, // 0-账密认证 1-混合认证 2-双因素认证
          auth: [
            {
              authType: this.form.auth.authType + "", //0-账密认证 1-短信认证
              sources: [this.form.auth.msp, this.form.auth.sources2],
            },
          ],
        };
        const res = await this.$axios.post(
          "/httpServe/auth/update",
          params,
          true
        );
        this.$store.commit('platformFlag','1')
        this.$store.commit('sourcesTwoFlag','2')
      } else if (this.form.type == 2) {
        if (
          new Date(this.ruleForm.start).getTime() >
          new Date(this.ruleForm.end).getTime()
        ) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.startGreaterEndToast'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.ruleForm.timeStart > this.ruleForm.timeEnd) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.StartGreaterEqual'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.ruleForm.start == null && this.ruleForm.end != null) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.startCannotEmptyToast'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.ruleForm.start != null && this.ruleForm.end == null) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.endCannotEmptyToast'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.ruleForm.start == null && this.ruleForm.end == null) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.CannotEmpty'),
            type: "error",
            offset: 100,
          });
          return false;
        }
        if (this.content.length <= 0) {
          this.$message({
            message: this.$t('System.SecurityConfigPage.atLeastACondition'),
            type: "error",
            offset: 100,
          });
          return;
        }
        if (this.ruleForm.valid == "0") {
          var params = {
            type: this.form.type, // 0-账密认证 1-混合认证 2-双因素认证
            auth: [
              {
                authType: this.form.auth.authType + "", //0-账密认证 1-短信认证
                sources: [this.form.auth.twoFactor],
              },
              {
                authType: this.form.auth.msgType + "", //0-账密认证 1-短信认证
                sources: [this.form.auth.twoSources],
                // rule:this.content,
                rule: this.content.map(Number),
                errTime: {
                  valid: this.ruleForm.valid, //0-永久有效 1-自定义
                  start: '', //自定义起始时间
                  end: '', //自定义结束时间
                  weekdays: this.ruleForm.weekdays.toString(), //时间周期
                  timeStart: this.ruleForm.timeStart, //定时
                  timeEnd: this.ruleForm.timeEnd,
                },
              },
            ],
          };
        }
        if (this.ruleForm.valid == "1") {
          var params = {
            type: this.form.type, // 0-账密认证 1-混合认证 2-双因素认证
            auth: [
              {
                authType: this.form.auth.authType + "", //0-账密认证 1-短信认证
                sources: [this.form.auth.twoFactor],
              },
              {
                authType: this.form.auth.msgType + "", //0-账密认证 1-短信认证
                sources: [this.form.auth.twoSources],
                // rule:this.content,
                rule: this.content.map(Number),
                errTime: {
                  valid: this.ruleForm.valid, //0-永久有效 1-自定义
                  start: this.ruleForm.start, //自定义起始时间
                  end: this.ruleForm.end, //自定义结束时间
                  weekdays: this.ruleForm.weekdays1.toString(), //时间周期
                  timeStart: this.ruleForm.timeStart1, //定时
                  timeEnd: this.ruleForm.timeEnd1,
                },
              },
            ],
          };
        }

        console.log(this.form.auth.msgType,params, "======生物认证======");
        const res = await this.$axios.post(
          "/httpServe/auth/update",
          params,
          true
        );
        this.$store.commit('platformFlag','2')
        this.$store.commit('sourcesTwoFlag','1')
      }
      this.$message({
        message: "保存成功",
        type: "success",
        offset: 100,
      });
    
      sessionStorage.removeItem("perObj"); //删除名称为“key”的信息
      this.SMSlist();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.smsadd();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
    //弹窗展示异常时间配置
    conTime() {
      this.conTimeVisible = true; //弹框打开
      var start = this.$common.getDate();
      var end = this.$common.getAnyMonth("-", +1);
      const infoPer = JSON.parse(window.sessionStorage.getItem("perObj"));
      if (JSON.parse(window.sessionStorage.getItem("perObj"))) {
        const infoPer = JSON.parse(window.sessionStorage.getItem("perObj"));
        if (infoPer.valid == "0") {
          this.ruleForm.weekdays = infoPer.weekdays.split(",").map(String);
          this.ruleForm.timeStart = infoPer.timeStart;
          this.ruleForm.timeEnd = infoPer.timeEnd;
          this.ruleForm.weekdays1 = [];
          this.ruleForm.timeStart1 = "00:00";
          this.ruleForm.timeEnd1 = "24:00";
        }
        if (infoPer.valid == "1") {
          this.ruleForm.weekdays1 = infoPer.weekdays.split(",").map(String);
          this.ruleForm.timeStart1 = infoPer.timeStart1;
          this.ruleForm.timeEnd1 = infoPer.timeEnd1;
          this.ruleForm.weekdays = [];

          this.ruleForm.timeStart = "00:00";
          this.ruleForm.timeEnd = "24:00";
          this.ruleForm.start = infoPer.start;
          this.ruleForm.end = infoPer.end;
        }
      } else {
        if (this.dataObj.auth[1]) {
          this.content = this.dataObj.auth[1]
            ? this.dataObj.auth[1].rule.map(String)
            : [];
          this.ruleForm.valid = this.dataObj.auth[1]
            ? this.dataObj.auth[1].errTime.valid
            : "";
          if (this.dataObj.auth[1].errTime.valid == "1") {
            this.ruleForm.weekdays1 = this.dataObj.auth[1].errTime.weekdays
              .split(",")
              .map(String);
            this.ruleForm.timeStart1 = this.dataObj.auth[1].errTime.timeStart;
            this.ruleForm.timeEnd1 = this.dataObj.auth[1].errTime.timeEnd;
            this.ruleForm.weekdays = [];
            this.ruleForm.timeStart = "00:00";
            this.ruleForm.timeEnd = "24:00";
            this.ruleForm.start = this.dataObj.auth[1].errTime.start;
            this.ruleForm.end = this.dataObj.auth[1].errTime.end;
          }
          if (this.dataObj.auth[1].errTime.valid == "0") {
            this.ruleForm.weekdays = this.dataObj.auth[1].errTime.weekdays
              .split(",")
              .map(String);
            this.ruleForm.timeStart = this.dataObj.auth[1].errTime.timeStart;
            this.ruleForm.timeEnd = this.dataObj.auth[1].errTime.timeEnd;
            this.ruleForm.weekdays1 = [];
            this.ruleForm.timeStart1 = "00:00";
            this.ruleForm.timeEnd1 = "24:00";
          }
        }
      }
    },
    //取消关闭异常时间配置项弹框
    cancel() {
      var start = this.$common.getDate();
      var end = this.$common.getAnyMonth("-", +1);
      if (this.ruleForm.valid == "0") {
        (this.ruleForm.weekdays = []),
          (this.ruleForm.valid = "0"),
          (this.ruleForm.timeStart = "00:00"),
          (this.ruleForm.timeEnd = "24:00");
      }
      if (this.ruleForm.valid == "1") {
        (this.ruleForm.weekdays1 = []),
          (this.ruleForm.valid = "0"),
          (this.ruleForm.timeStart1 = "00:00"),
          (this.ruleForm.timeEnd1 = "24:00"),
          (this.ruleForm.start = start),
          (this.ruleForm.end = end);
      }
      this.conTimeVisible = false; //弹框关闭
    },
    handleCloseConTime() {
      this.conTimeVisible = false; //弹框关闭
    },
  },
};
</script>

<style lang="scss" scoped>
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}
::v-deep .box-corn {
  height: 280px !important;
}
::v-deep .box-wrap {
  height: 330px !important;
}
::v-deep .box-corn .el-form-item {
  margin-bottom: 12px;
}
::v-deep .box-wrap .el-form-item {
  margin-bottom: 12px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
